import React from 'react'
import * as t from 'utils/Typography'
import Layout from 'components/Layout'
import ContentWrapper from 'components/Content'
import { myContext } from 'src/themeProvider'
import { Background, AboveFold } from 'utils/styles'

function RenderPage(props) {
  const { theme, changeTheme } = props

  return (
    <Background theme={theme}>
      <Layout theme={theme} onChangeTheme={changeTheme}>
        <AboveFold theme={theme}>
          <ContentWrapper theme={theme} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <t.H1 primary align="center">
              WIP
            </t.H1>
            <t.H3 secondary align="center">
              Work in progress
            </t.H3>
          </ContentWrapper>
        </AboveFold>
      </Layout>
    </Background>
  )
}

function UpturnPage(props) {
  const {data} = props
  return (
    <myContext.Consumer>
      {(context) => <RenderPage theme={context.theme} changeTheme={context.changeTheme} data={data} />}
    </myContext.Consumer>
  )
}

export default UpturnPage
